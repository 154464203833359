import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Button, Slide, useScrollTrigger } from '@mui/material';
import { useAuthService } from '../services/auth.service';
import { chatApi, useListAllChatSessionsQuery } from '../apis/chat.api';
import { useChatService } from '../services/chat.service';
import { setCurrentSession } from '../slices/chat.slice';
import { useDispatch, useSelector } from 'react-redux';
import ChatElement from './ChatElement';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


export default function PersistentDrawerLeft() {
  const { data: chat_sessions } = useListAllChatSessionsQuery(null);
  //const [currentChatSession, setCurrentChatSession] = React.useState<any | null>(null);
  const dispatch = useDispatch();
  const currentChatSession = useSelector((state: any) => state.chat.session);
  React.useEffect(()=>{
    if(currentChatSession==undefined) {
      startNewChat(null);
    }
  }, [currentChatSession]);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { logout } = useAuthService();
  const { startNewChat } = useChatService();
  const handleLogout = async () => {
    await logout();
  };
  const handleStartNewChat = async () => {
    await startNewChat(null);
    
  };
  React.useEffect(() => {
    if (chat_sessions == null) return;
    if (chat_sessions.length != 0) dispatch(setCurrentSession(chat_sessions[0]));
}, [chat_sessions]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
        <AppBar position="fixed" open={open} style={{ background: 'transparent', boxShadow: 'none'}}>
          <Toolbar>
            {/*
<IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon style={{ color: "#004B57" }} />
            </IconButton>

            */}
            
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

            </Typography>
            <Button onClick={handleLogout}>Se déconnecter</Button>
          </Toolbar>
        </AppBar>
      {/**
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Button onClick={handleStartNewChat} style={{textTransform:"none", color:"#004B57", justifyContent:"start", paddingLeft: "20px"}}>Nouvelle conversation</Button>
        <Divider />
        <List>
          {chat_sessions && chat_sessions.map((session : any, index : number) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={()=>{dispatch(setCurrentSession(chat_sessions[index]))}}>
                <ListItemText 
                  primaryTypographyProps={{ 
                    variant: 'subtitle2', 
                    style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }
                }}
                primary={session.messages.length != 0 ? session.messages[session.messages.length - 1]["text"] : "Conversation vide"} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
       */}
      <Main open={open}>
        {/* <DrawerHeader /> */}
        { currentChatSession && <ChatElement session={currentChatSession}/> }
      </Main>
    </Box>
  );
}
