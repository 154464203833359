import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './reducer'
import { authApi } from '../apis/auth.api'; // Assuming you have an auth API slice
import { persistStore } from 'redux-persist';

import { isRejectedWithValue } from '@reduxjs/toolkit'
import { setUserToken } from '../slices/auth.slice';
import { chatApi } from '../apis/chat.api';
import { userApi } from '../apis/user.api';


/**
 * Logout the user if 401 error
 */
export const unauthorizedMiddleware =
  (store : any) => (next : any) => (action : any) => {
    if (isRejectedWithValue(action) && action.payload.status == 401) {
      // let state = store.getState();
      store.dispatch(setUserToken(null));
    }
    return next(action)
  }

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }).concat(
    authApi.middleware, 
    chatApi.middleware, 
    userApi.middleware, 
    unauthorizedMiddleware
  ),
  devTools: process.env.NODE_ENV !== 'production',
})
export const persistor = persistStore(store);