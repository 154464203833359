import { createSlice } from '@reduxjs/toolkit'

const initialState = { token: null, profile : null } 

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserToken(state,  action) {
      state.token = action.payload;
    },
    unsetUserToken(state) {
      state.token = null;
    },
    setUserProfile(state,  action) {
      state.profile = action.payload;
    },
    unsetUserProfile(state) {
      state.profile = null;
    },
  },
})

export const { setUserToken, unsetUserToken, setUserProfile, unsetUserProfile } = authSlice.actions
export default authSlice.reducer