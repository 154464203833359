import { Box, Button, Chip, Container, FormControl, FormLabel, IconButton, Paper, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import * as React from 'react';
import Markdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links'
import Message from './Message';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useSelector } from 'react-redux';
import { useChatService } from '../services/chat.service';
import Textarea from '@mui/joy/Textarea';
interface MessageData {
  text: string;
  isUser: boolean;
  media: string[]
}
interface Media {
  data: string;
  file: File;
}

export default function ChatElement({ session }: any) {

  const [messages, setMessages] = React.useState<MessageData[]>([]);
  const [input, setInput] = React.useState('');
  const [currentRole, setCurrentRole] = React.useState('user');

  const [selectedMediaList, setSelectedMediaList] = React.useState<Media[]>([]);

  const [lastMessage, setLastMessage] = React.useState<MessageData | null>(null);
  const [advice, setAdvice] = React.useState<MessageData | null>(null);
  const [context, setContext] = React.useState<string>(""); // "Tu es une personne très drôle. Réponds toujours avec un air amusant. N'hésite pas à faire des blagues."

  const [isLoading, setIsLoading] = React.useState(false);
  const [isGenerating, setIsGenerating] = React.useState(false);


  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const userProfile = useSelector((state: any) => state.auth.profile);
  const { sendMessage, appendMessage, advise } = useChatService();

  const handleContextChange = (event: any) => {
    console.log(event.target.value);
    setContext(event.target.value);
};
  const render = (text: string): any => {
    return <Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}>{text}</Markdown>
  }
  React.useEffect(() => {
    if (session == null) return;

    const initialMessages: MessageData[] = [];
    session.messages.forEach((message: any) => {
      initialMessages.push({
        "text": render(message.text),
        "isUser": message.sender == "user",
        "media": message.media ?? []

        /*"media": [
          "https://plus.unsplash.com/premium_photo-1688045722767-8d8672f6950b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          "https://images.unsplash.com/photo-1720728659925-9ca9a38afb2c?q=80&w=1970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        ]*/
      });
    });
    console.log(initialMessages);
    setMessages(initialMessages);
  }, [session]);

  const handleCurrentRoleChange = (value: string) => {
    console.log(value);
    setCurrentRole(value);
  }

  const adviseAgent = async () => {
    try {
      setIsGenerating(true);
      let advise_responses = await advise(session.session_id, context);


      let generated_advice_response = "";
      for await (const res of advise_responses) {
        console.log("Kevin", res);
        generated_advice_response += res
        setAdvice({
          "text": render(generated_advice_response),
          "isUser": false,
          "media": []
        });

      }

    }
    catch (e) {
      console.error(e);
    }
    finally {
      setIsGenerating(false);
    }
  };
  const handleSend = async () => {
    try {
      if (input.trim() == "") {
        alert("You should provide a message text to continue.");
        return;
      }

      let role: string = currentRole;

      const newMessage: MessageData = {
        text: input,
        isUser: role == "user",
        media: selectedMediaList.map((media) => media.data)
      };

      let newMessages = [...messages, newMessage]
      setMessages(newMessages);
      setInput('');

      setSelectedMediaList([]);
      let messageResult = null;

      /*
      setMessages([...newMessages, {
        text: render("Chargement..."),
        isUser: false,
        media:[]
        }]);
        */


      console.log(session);
      let responses = await appendMessage({
        "message": input,
        "role": role,
        "sessionId": session.session_id
      });
      console.log("CONVERSATION", responses);

      setIsLoading(false);
      setLastMessage(null);


      // Call Advise
      await adviseAgent();
    }
    catch (e) {
      console.error(e);
    }
  };
  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedMediaListTmp: Media[] = [];
    setSelectedMediaList([]);
    if (event.target.files && event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = (e) => {
            let media: Media = {
              data: e.target?.result as string,
              file: file
            }
            console.log("media", media);
            // setSelectedImage(e.target?.result as string);
            selectedMediaListTmp.push(media)
            setSelectedMediaList(selectedMediaListTmp);

          };
          reader.readAsDataURL(file);

        } else {
          console.error('Please select an image file.');
        }
      }
      event.target.value = "";
    }
  };
  const triggerImageInput = () => {
    fileInputRef.current?.click();
  };
  const chipSelected = {
    borderColor: "#004B57",
    color: "#004B57",
  }

  const deleteMedia = (index: number) => {
    let selectedMediaListTmp = selectedMediaList.slice();
    selectedMediaListTmp.splice(index, 1);
    setSelectedMediaList(selectedMediaListTmp);
  }

  React.useEffect(() => {
    console.log(selectedMediaList);
  }, [selectedMediaList]);

  return (
    <Container style={{ display: 'flex', background: 'white', height: '95vh', borderRadius: '10px', flexWrap: "wrap" }}>
      <Box sx={{
        height: '100%',
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        minWidth: "500px"
      }}>
        <Box sx={{ p: 2, textAlign: "start" }}>
          <h1 style={{ fontSize: 75, marginBottom: 15, color: "#004B57" }}>Agent augmenté</h1>
          {/*<p className='subtitle' style={{ fontSize: 40, marginTop: "0px", fontWeight: "500", marginBottom: "10px" }}>Faites vous aider par l'IA, simulez une discussion avec un client içi.</p>*/}
          <FormControl fullWidth style={{ marginBottom: 15, maxHeight:150 }}>
                <FormLabel>Mise en contexte</FormLabel>
                <Textarea onChange={handleContextChange} value={context} minRows={5} variant="outlined" placeholder="Mettez ici le sujet ou le contexte dont vous voulez parler avec l'IA" />
            </FormControl>
          <ToggleButtonGroup
            color="primary"
            value={currentRole}
            exclusive
            onChange={(ev, value) => { handleCurrentRoleChange(value) }}
            aria-label="Role"
          >
            <ToggleButton value="user">Client</ToggleButton>
            <ToggleButton value="agent">Téléconseiller</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box sx={{
          flexGrow: 1,
          overflowY: 'auto',
          minHeight:250,
          p: 2,
          display: 'flex',
          flexDirection: 'column'
        }}>
          {messages.map((msg, index) => (
            <Message key={index} {...msg} />
          ))}

        </Box>

        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: "nowrap", overflow: "auto", gap: "10px", paddingBottom: "10px" }}>
              {
                selectedMediaList.map((media, index) => {
                  return (
                    <div style={{ position: "relative" }} key={index}>
                      <img src={media.data} alt="Selected" style={{ height: '150px', borderRadius: '4px' }} />
                      <IconButton style={{ color: "#004B57", top: 0, left: 0, position: "absolute" }} onClick={() => { deleteMedia(index) }}>
                        <CloseIcon />
                      </IconButton>
                    </div>

                  )
                })
              }

            </Box>
            <Box sx={{ display: 'flex', mb: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Écrivez votre message ici..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleImageSelect}
                accept="image/*"
                multiple={true}
              />
              {
                /*
                <IconButton style={{color:"#004B57"}} onClick={triggerImageInput}>
                <ImageIcon />
              </IconButton>
                */
              }

              <IconButton style={{ color: "#004B57" }} onClick={handleSend}>
                <SendOutlinedIcon />
              </IconButton>
            </Box>

          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "20px", width: "40%", height: "95vh" }}>
        <Box sx={{ background: "#eeeeee", padding: "20px", borderRadius: "10px", textAlign: "left", height: "100%", overflow: "auto" }}>
          {advice &&
            <>
              <Typography variant="body1" style={{ wordWrap: "break-word" }}>{advice.text}</Typography>
              
              < Button disabled={isGenerating} startIcon={<AutoAwesomeIcon />} onClick={adviseAgent} disableElevation fullWidth type="submit" variant="contained" style={{ textTransform: 'none', backgroundColor: "#004B57", width: "auto", float: "right", color: "#FFFFFF", opacity: isGenerating ? 0.5 : 1}}>
                Regénérer
              </Button>
              

            </>}
        </Box>
      </Box>
    </Container>
  );
}
