import { useLoginMutation } from '../apis/auth.api';
import { useDispatch } from 'react-redux';
import { setUserProfile, setUserToken } from '../slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import { chatApi } from '../apis/chat.api';
import { setCurrentSession } from '../slices/chat.slice';

export const useAuthService = () => {
  const navigate = useNavigate();
  const [loginMutation] = useLoginMutation();

  const dispatch = useDispatch();

  const logout = async () => {
    try {
      dispatch(chatApi.util.resetApiState());
      
      dispatch(setUserToken(null));
      dispatch(setCurrentSession(null));
    } catch (error) {
      throw error;
    }
  };

  const login = async (credentials : any) => {
    try {
      const result = await loginMutation(credentials).unwrap();
      console.log(result);
      dispatch(setUserToken(result.access_token));

      navigate('/');
      return result;
    } catch (error) {
      throw error;
    }
  };


  return { login, logout};
};