import { useDispatch } from 'react-redux';
import { chatApi, useAppendMessageMutation, useStartNewChatSessionMutation } from '../apis/chat.api';
import { setCurrentSession } from '../slices/chat.slice';

export const useChatService = () => {
  const [appendMessageMutation] = useAppendMessageMutation();
  const [startNewChatSession] = useStartNewChatSessionMutation();


  
  const dispatch = useDispatch();
  async function* sendMessage (body : any) {
    const ctrl = new AbortController();
    try {
      let sessionId = body['sessionId'];
      delete body['sessionId'];

      var formDatas = new FormData();
      formDatas.append('prompt', body['message']);
        // headers: {'Content-Type': 'text/event-stream'}, 
      /*const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chat/sessions/${sessionId}`, { 
        method: "POST",
        signal:ctrl.signal, // To abort the call when needed
        body : formDatas
      });*/

      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/test`, { 
        method: "GET",
        signal:ctrl.signal, // To abort the call when needed
      });
      const reader = response.body!.getReader();
      const decoder = new TextDecoder("utf-8");
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        let res = decoder.decode(value);
        yield res
      }
    } catch (error) {
      // ctrl.abort()
      throw error;
    }
  };
  async function* advise (session_id : string, context : string) {
    const ctrl = new AbortController();
    try {
      let formDatas = new FormData();
      formDatas.append("context" , context);
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chat/sessions/${session_id}/advise`, { 
        method: "POST",
        signal:ctrl.signal, // To abort the call when needed
        body: formDatas
      });
      const reader = response.body!.getReader();
      const decoder = new TextDecoder("utf-8");
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        let res = decoder.decode(value);
        yield res
      }
    } catch (error) {
      // ctrl.abort()
      throw error;
    }
  };
  const startNewChat = async (body : any) => {
    try {
      const result = await startNewChatSession(body).unwrap();
      dispatch(setCurrentSession(result)); 
      return result;
    } catch (error) {
      throw error;
    }
  };

  const appendMessage = async (body : any) => {
    try {
      const result = await appendMessageMutation(body).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };

  return { sendMessage, startNewChat, appendMessage, advise };
};