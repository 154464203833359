import { createSlice } from '@reduxjs/toolkit'

const initialState = { session: null } 

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCurrentSession(state,  action) {
      state.session = action.payload;
    },
  },
})

export const { setCurrentSession } = chatSlice.actions
export default chatSlice.reducer