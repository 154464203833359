import { combineReducers } from 'redux';

// reducer import
import authReducer from '../slices/auth.slice';
import chatReducer from '../slices/chat.slice';

import { authApi } from '../apis/auth.api'; 
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/es/storage/session';
import { chatApi } from '../apis/chat.api';
import { userApi } from '../apis/user.api';
// ==============================|| COMBINE REDUCER ||============================== //

const persistConfig = {
  key: 'root',
  storage:sessionStorage,
}
const authPersistedReducer = persistReducer(persistConfig, authReducer);

const reducer = combineReducers({
  auth : authPersistedReducer,
  chat: chatReducer,
  [authApi.reducerPath]: authApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [userApi.reducerPath]: userApi.reducer
});

export default reducer;
