import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';
import LoginForm from '../auth/AuthLogin';
import AuthGuard from './AuthGuard';
import PersistentDrawerLeft from '../chatbot/MainLayout';

// =================
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return (
    <Routes>
      <Route path="/auth">
        <Route path="login" element={<LoginForm />} />
        <Route path='' element={<Navigate to='/auth/login' />} />
      </Route>
      
      <Route element={<AuthGuard />}>
        <Route path="/">
          <Route path="workspace">
            <Route path="chat" element={<PersistentDrawerLeft />} />
            <Route path='' element={<Navigate to='/workspace/chat' />} />
          </Route>
          <Route path='' element={<Navigate to='/workspace' />} />
        </Route>
      </Route>
    </Routes>
  )
}
