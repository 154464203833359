import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Avatar, Paper } from '@mui/material';
import ZoomableImage from './ZoomableImage';
import './Message.css';
interface MessageProps {
    text: string;
    isUser: boolean;
    image?: string;
    media: string[]
}

export default function Message({ text, isUser, media }: MessageProps) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: isUser ? 'flex-end' : 'flex-start', mb: 2 }} className="message">
            {
                media.map((item)=>{
                    return (<Box sx={{ mb: 1, maxWidth: '30%', borderRadius: '12px', overflow: 'hidden', border: '1px solid #e0e0e0' }}>
                        <ZoomableImage src={item} alt="Image" />
                    </Box>)
                })
            }
            <Box sx={{ display: 'flex', alignItems: 'flex-end' , maxWidth:"75%"}}>
                {!isUser && (
                    <Avatar sx={{ bgcolor: '#004B57', mr: 1 }}>
                        B
                    </Avatar>
                )}
                <Paper elevation={1} sx={{ 
                    p: 2,
                    bgcolor: isUser ? '#004B57' : '#eeeeee',
                    borderRadius: '12px',
                    borderBottomRightRadius: isUser ? '0' : '12px',
                    borderBottomLeftRadius: isUser ? '12px' : '0',
                    //maxWidth:"75%",
                }}>
                    <Typography variant="body1" color={isUser ? 'white' : 'text.primary'} style={{textAlign: 'left', wordWrap:"break-word"}}>{text}</Typography>
                </Paper>
                {isUser && (
                    <Avatar sx={{ bgcolor: '#4F4A8C', ml: 1 }}>
                        <PersonOutlineOutlinedIcon/>
                    </Avatar>
                )}
            </Box>
        </Box>
    );
}