
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

// Define your authentication guard component
const AuthGuard = () => {
    const location = useLocation();
    const token = useSelector((state : any) => state.auth.token);

    // Redirect to login if not authenticated
    if (token==null && location.pathname !== '/auth/login') {
        return <Navigate to="/auth/login" />;
    }

    // Render the protected route if authenticated
    return <Outlet />
};

export default AuthGuard;